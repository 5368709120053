@import "../../styles/_variables.css";

/* INSIGHT mobile header */

.overlayOn {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 25%;
  left: 0px;
  z-index: 2;
  display: block !important;
  background: var(--very-light-grey);
}

.overlay {
  display: none;
}

.user-name {
  font-size: var(--font-size-h1);
  color: var(--black);
  letter-spacing: 1.2px;
}

.info-box {
  margin-left: 20px;
  margin-top: 40px;
}

.link-setting {
  display: block;
  letter-spacing: 1px;
  line-height: 26px;
  font: var(--font-family-simplon);
  font-size: var(--font-size-large);
}

.mobile-header-container {
  position: relative;
  background: var(--blue);
  height: 50px;
  text-align: center;
  padding: 0 50px;
}

.header-logo-container {
  padding: 14px 5px 10px 5px; /* Accommodate odd baseline of Simplon */
}

.header-logo {
  display: block;
  font-family: var(--font-family-simplon);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2.2px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--white) !important;
  & sup {
    font-size: 10px;
    letter-spacing: 1.2px;
    line-height: 13px;
    margin-left: 3px;
    vertical-align: super;
    top: auto;
  }
}

.mobile-header-menu {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  padding: 13px;
  background-color: transparent;
  border: none;
}

.header-container {
  height: 40px;
  background-color: #007ea8;
  display: none;
}

@media (min-width: 600px) {
  .header-container {
    display: block;
  }

  .mobile-header-container {
    display: none;
  }

  .learn-more {
    font-weight: bold;
    padding-top: 9px;
    display: inline-block;
  }

  .header-logo-container {
    /* width: 250px; */
    padding: 9px 20px 5px 20px; /* Accommodate odd baseline of Simplon */
  }

  .search-box-container {
    display: inline-block;
    width: calc(100% - 250px);
  }
}
