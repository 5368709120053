/* Roboto */
@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i");

/* Material Icons */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

/* Simplon Regular */
@font-face {
  font-family: "Simplon Norm";
  src: url("../fonts/simplonnorm-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Simplon Regular Italic */
@font-face {
  font-family: "Simplon Norm";
  src: url("../fonts/simplonnorm-regularitalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

/* Simplon Bold */
@font-face {
  font-family: "Simplon Norm";
  src: url("../fonts/simplonnorm-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

/* Simplon Bold Italic */
@font-face {
  font-family: "Simplon Norm";
  src: url("../fonts/simplonnorm-bolditalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

/* Simplon Light */
@font-face {
  font-family: "Simplon Norm";
  src: url("../fonts/simplonnorm-light.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

/* Simplon Light Italic */
@font-face {
  font-family: "Simplon Norm";
  src: url("../fonts/simplonnorm-lightitalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

/* Simplon Medium */
@font-face {
  font-family: "Simplon Norm";
  src: url("../fonts/simplonnorm-medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

/* Simplon Medium Italic */
@font-face {
  font-family: "Simplon Norm";
  src: url("../fonts/simplonnorm-mediumitalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
