@import "../../styles/_variables.css";

/* Header Base */
.component {
  background-color: var(--blue-dark);
  width: 100%;
}

@media (max-width: 599px) {
  .top {
    display: none;
  }
}

.top {
  background: var(--charc);
  padding: 8px 68px 8px 10px;
  min-height: 36px;
  transition: var(--transition-all);

  & .top-left {
    float: left;
    min-height: 1px;
  }

  & .link-wrap {
    float: right;
    min-height: 1px;
    height: 20px;
  }

  & .top-link {
    display: inline-block;
    height: 16px;
    font-size: calc(var(--font-size-base) - 6);
    padding: 4px;
  }

  & .help {
    margin-left: 8px;
    border: 1px solid var(--blue-lighter-alt);
    opacity: 0.5;
    &:hover {
      cursor: default;
    }
  }
}

.breakpoint-4 {
  &.component {
    border-bottom: 1px solid var(--white-transp-darker);
    /*height: 53px;*/
    height: auto;
    &.homepage {
      border-bottom: none;
      height: auto;
    }
  }
  & .top {
    width: auto;
    padding: 0;
    z-index: 1;
  }
}

/* Navigation Menu Start*/

*,
*:before,
*:after {
  box-sizing: border-box;
}

nav,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

nav {
  display: block;
}
ol,
ul {
  list-style: none;
}
nav.menu {
  background: transparent;
  position: relative;
  min-height: 40px;
  height: 100%;
  float: left;
  transition: width 1s;
  & ul {
    & li {
      list-style: none;
      display: inline-block;
      color: #fff;
      line-height: 40px;
      & a {
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fff;
        min-width: 160px;
        //padding: 0px 20px;
        text-align: center;
        display: block;
        font-weight: bold;
        font-size: var(--font-size-h6);
      }
      & a:hover {
        background: var(--blue-darker);
        color: #fff;
        transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
      }
    }
  }
  & .active {
    background: var(--blue-darker) !important;
  }
}

/* Navigation Menu End*/

.navigation {
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid var(--white-transp-darker);
  & button {
    height: 36px !important;
    padding: 10px 20px;
    z-index: 4;
    border: 0;
    background: transparent;
    transition: var(--transition-all);
    &:hover {
      cursor: pointer;
      opacity: 0.65;
    }
    & i {
      color: #fff;
    }
  }
  & ul {
    margin-top: -1px;
    margin-right: -1px;
  }
}

.breakpoint-4 {
  & .navigation {
    padding: 0 2px;
    & button {
      height: 52px !important;
    }
  }
}

/* Simple Search */
.simple-search {
  & .page-slider {
    z-index: 5;
  }
}

/* DJ Logo */
.dj-logo {
  display: block;
  float: left;
  height: 16px;
  & > img {
    position: relative;
    top: 3px;
    left: 10px;
    height: 14px;
  }
}

.dj-logo-mobile {
  display: block;
  float: left;
  width: 52px;
  height: 52px;
  text-align: center;
  border-right: 1px solid var(--white-transp-darker);
  & > img {
    position: relative;
    top: 15px;
    width: 18px;
  }
}

/* Product Logo */

.product--name {
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  display: inline;
  letter-spacing: 1.5px;
  font-weight: 600;
  margin: 0;
  a {
    color: #fff;
  }
}
.product--section {
  color: #fff;
  display: inline;
  text-transform: uppercase;
  padding-left: 5px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 15px;
  margin-left: 3px;
  a {
    color: #fff;
  }
}

/* RC Logo */
.rc-logo-wrap {
  display: table;
}

.rc-logo-search {
  float: left;
  background-color: var(--blue);
}

.rc-logo-base {
  display: table-cell;
  vertical-align: middle;
  & img {
    position: relative;
    top: 2px;
    width: 100%;
  }
}

.rc-logo-mobile {
  composes: rc-logo-base;
  height: 52px;
  padding: 0 101px 0 15px;
  & img {
    max-width: 180px;
  }
}

.rc-logo {
  composes: rc-logo-base;
  height: 40px;
  padding: 0 20px;
  transition: width 1s;
}

/* Animations/Transitions */
.animate {
  & .rc-logo-wrap {
    height: 40px;
    overflow: hidden;
    & .rc-logo {
      position: relative;
      animation: slidetop 0.25s ease-in-out;
    }
  }
}

.slidebottom {
  position: relative;
  animation: slidebottom 0.25s ease-in-out, fade 0.25s ease-in;
}

.active-nav {
  background-color: #017295;
}
/* Animation Definitions */
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slidebottom {
  0% {
    top: 100px;
  }
  100% {
    top: 0;
  }
}
@keyframes slidetop {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
@keyframes slidesearch {
  0% {
    top: -36px;
  }
  100% {
    top: 0;
  }
}
@keyframes slidesearch-small {
  0% {
    top: 36px;
  }
  100% {
    top: 0;
  }
}

@media print {
  .component {
    display: none;
    margin: 0 !important;
    & .top {
      display: none !important;
    }
    & nav {
      display: none !important;
    }
  }
}
