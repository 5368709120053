@import "../../styles/_variables.css";

.component {
  & li {
    &.divider {
      height: 1px;
      margin: 1px 0;
      background-color: var(--grey-lighter-alt);
    }
    &.selected {
      background-color: var(--grey-lighter-alt);
      & > a {
        cursor: default;
      }
    }
    & > a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: var(--font-weight-normal);
      color: var(--grey-dark);
      white-space: nowrap;
      cursor: pointer;
      &:hover,
      &:focus {
        text-decoration: none;
        color: var(--grey-darker);
        background-color: var(--grey-lighter-alt);
      }
    }
  }

  & button {
    &:hover {
      opacity: 1 !important;
    }
    & > i {
      color: var(--grey-dark) !important;
    }
  }
}
