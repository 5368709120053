@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* Fixed heights */
  --header-height: 76px;
  --footer-height: 128px;

  /* Colors
     *****************************************************************************
     Gray and brand colors for use across application
    */
  --roboto: Roboto-Regular;
  --charc: #0c2a31; /* "Dark Charc" */
  --charc-medium: #394859;
  --charc-transp: rgba(0, 0, 0, 0.15);
  --charc-transp-darker: rgba(0, 0, 0, 0.35);
  --grey-darkest: #26383d;
  --hover-grey: #dee1e4;
  --grey-darker: #4f5b66;
  --grey-dark: #8d9ca9;
  --grey: #a7adba;
  --grey-transp: rgba(57, 72, 89, 0.25);
  --grey-light: #dddddd;
  --grey-light-alt: #d4d3d3;
  --grey-lighter: #f4f4f4;
  --grey-lighter-alt: #eee;
  --grey-medium: #a3a3a3;
  --grey-medium-alt: #bbc0c4;
  --red: #ec5f67;
  --red-dark: #d6414a;
  --red-darker: #c53727;
  --red-darkest: #e2200a;
  --orange: #f99157;
  --yellow: #fac863;
  --green: #99c794;
  --blue-light: #5fb3b3;
  --light-shiny-blue: #00a4d6;
  --blue: #007ea8; /* "Berg Blue" */
  --blue-dark: #0f6c8d; /* "Berg Tint 2" */
  --blue-darker: #017295; /* "Berg Tint 3" */
  --blue-darkest: #014155; /* "Berg Tint 4" */
  --blue-link: #3d6aa6; /* Default link color */
  --blue-lighter: #f5f9fc; /* "Berg Tint 1" */
  --blue-lighter-alt: #00c3ff;
  --aqua-blue: #26b6df;
  --sky-blue: #c9f0fb;
  --light-grey: #c8c8c8;
  --very-light-grey: #f4f4f4;
  --grey-border: #dee1e0;
  --dark-grey: #143138;
  --purple: #c594c5;
  --brown: #ab7967;
  --black: #000000;
  --white: #ffffff;
  --white-transp: rgba(255, 255, 255, 0.35);
  --white-transp-darker: rgba(255, 255, 255, 0.15);
  --highlightColor: hwb(190, 35%, 20%);

  --successColor: #3c763d;
  --warningColor: #8a6d3b; /* #EFBF4F;*/
  --errorColor: #e34c53;
  --warningIcon: #f0c043;
  /* End Colors */

  /* Typography
     *****************************************************************************
     Font, line-height, and color for body text
    */

  --font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-serif: Georgia, "Times New Roman", Times, serif;
  --font-family-roboto: "Roboto";
  --font-family-simplon: "Simplon Norm";
  --font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
  --font-family-zawgyi-one: "Zawgyi-One";
  --font-family-base: var(--font-family-sans-serif);
  --font-family-simplon-bold: "SimplonNorm-Bold";
  --font-family-simplon-light: "SimplonNorm-Light";
  --font-family-simplon-medium: "SimplonNorm-Medium";

  --font-size-base: 15px;
  --font-size-h1: calc(var(--font-size-base) * 2.6);
  --font-size-h2: calc(var(--font-size-base) * 2.1);
  --font-size-h3: calc(var(--font-size-base) * 1.7);
  --font-size-h4: calc(var(--font-size-base) * 1.25);
  --font-size-h5: var(--font-size-base);
  --font-size-h6: calc(var(--font-size-base) * 0.85);
  --font-size-large: calc(var(--font-size-base) * 1.25);
  --font-size-small: calc(var(--font-size-base) * 0.8);
  --font-size-very-small: calc(var(--font-size-base) * 0.73);
  --font-size-normal: var(--font-size-base);
  --font-size-medium: calc(var(--font-size-base) * 0.9333333);
  --font-size-extra-large: calc(var(--font-size-base) * 1.4666666);

  --font-weight-normal: normal;
  --font-weight-light: light;
  --font-weight-bold: bold;

  --headings-font-family: inherit;
  --headings-font-weight: 500;
  --headings-line-height: 1.1;
  --headings-color: inherit;
  --headings-small-color: var(--grey-light);

  --line-height-base: 1.4;
  --line-height-computed: calc(var(--font-size-base) * var(--line-height-base));
  /* End Typography */

  /* Transitions */
  --default-transition-duration: 0.25s;
  --default-transition-easing: ease-in-out;
  --transition-all: all var(--default-transition-duration)
    var(--default-transition-easing);

  --max-site-width: 1280px;
}

.settings_component__KLzgS li.settings_divider__W-8Rj {
      height: 1px;
      margin: 1px 0;
      background-color: var(--grey-lighter-alt);
    }

.settings_component__KLzgS li.settings_selected__1-QUA {
      background-color: var(--grey-lighter-alt);
    }

.settings_component__KLzgS li.settings_selected__1-QUA > a {
        cursor: default;
      }

.settings_component__KLzgS li > a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: var(--font-weight-normal);
      color: var(--grey-dark);
      white-space: nowrap;
      cursor: pointer;
    }

.settings_component__KLzgS li > a:hover,
      .settings_component__KLzgS li > a:focus {
        text-decoration: none;
        color: var(--grey-darker);
        background-color: var(--grey-lighter-alt);
      }

.settings_component__KLzgS button:hover {
      opacity: 1 !important;
    }

.settings_component__KLzgS button > i {
      color: var(--grey-dark) !important;
    }

/* Header Base */
.header_component__1fkoT {
  background-color: var(--blue-dark);
  width: 100%;
}

@media (max-width: 599px) {
  .header_top__1XJtp {
    display: none;
  }
}

.header_top__1XJtp {
  background: var(--charc);
  padding: 8px 68px 8px 10px;
  min-height: 36px;
  transition: var(--transition-all);
}

.header_top__1XJtp .header_top-left__2XuaZ {
    float: left;
    min-height: 1px;
  }

.header_top__1XJtp .header_link-wrap__2oBjj {
    float: right;
    min-height: 1px;
    height: 20px;
  }

.header_top__1XJtp .header_top-link__inwAi {
    display: inline-block;
    height: 16px;
    font-size: calc(var(--font-size-base) - 6);
    padding: 4px;
  }

.header_top__1XJtp .header_help__pe3ly {
    margin-left: 8px;
    border: 1px solid var(--blue-lighter-alt);
    opacity: 0.5;
  }

.header_top__1XJtp .header_help__pe3ly:hover {
      cursor: default;
    }

.header_breakpoint-4__EDzSR.header_component__1fkoT {
    border-bottom: 1px solid var(--white-transp-darker);
    /*height: 53px;*/
    height: auto;
  }

.header_breakpoint-4__EDzSR.header_component__1fkoT.header_homepage__2R44L {
      border-bottom: none;
      height: auto;
    }

.header_breakpoint-4__EDzSR .header_top__1XJtp {
    width: auto;
    padding: 0;
    z-index: 1;
  }

/* Navigation Menu Start*/

*,
*:before,
*:after {
  box-sizing: border-box;
}

nav,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

nav {
  display: block;
}
ol,
ul {
  list-style: none;
}
nav.header_menu__4iihD {
  background: transparent;
  position: relative;
  min-height: 40px;
  height: 100%;
  float: left;
  transition: width 1s;
}
nav.header_menu__4iihD ul li {
      list-style: none;
      display: inline-block;
      color: #fff;
      line-height: 40px;
    }
nav.header_menu__4iihD ul li a {
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fff;
        min-width: 160px;
        //padding: 0px 20px;
        text-align: center;
        display: block;
        font-weight: bold;
        font-size: var(--font-size-h6);
      }
nav.header_menu__4iihD ul li a:hover {
        background: var(--blue-darker);
        color: #fff;
        transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
      }
nav.header_menu__4iihD .header_active__YaTf6 {
    background: var(--blue-darker) !important;
  }

/* Navigation Menu End*/

.header_navigation__364_d {
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid var(--white-transp-darker);
}

.header_navigation__364_d button {
    height: 36px !important;
    padding: 10px 20px;
    z-index: 4;
    border: 0;
    background: transparent;
    transition: var(--transition-all);
  }

.header_navigation__364_d button:hover {
      cursor: pointer;
      opacity: 0.65;
    }

.header_navigation__364_d button i {
      color: #fff;
    }

.header_navigation__364_d ul {
    margin-top: -1px;
    margin-right: -1px;
  }

.header_breakpoint-4__EDzSR .header_navigation__364_d {
    padding: 0 2px;
  }

.header_breakpoint-4__EDzSR .header_navigation__364_d button {
      height: 52px !important;
    }

/* Simple Search */
.header_simple-search__22S5G .header_page-slider__PD22q {
    z-index: 5;
  }

/* DJ Logo */
.header_dj-logo__1_IPV {
  display: block;
  float: left;
  height: 16px;
}
.header_dj-logo__1_IPV > img {
    position: relative;
    top: 3px;
    left: 10px;
    height: 14px;
  }

.header_dj-logo-mobile__2xCKZ {
  display: block;
  float: left;
  width: 52px;
  height: 52px;
  text-align: center;
  border-right: 1px solid var(--white-transp-darker);
}

.header_dj-logo-mobile__2xCKZ > img {
    position: relative;
    top: 15px;
    width: 18px;
  }

/* Product Logo */

.header_product--name__lgK7U {
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  display: inline;
  letter-spacing: 1.5px;
  font-weight: 600;
  margin: 0;
}

.header_product--name__lgK7U a {
    color: #fff;
  }
.header_product--section__3smAy {
  color: #fff;
  display: inline;
  text-transform: uppercase;
  padding-left: 5px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 15px;
  margin-left: 3px;
}
.header_product--section__3smAy a {
    color: #fff;
  }

/* RC Logo */
.header_rc-logo-wrap__2GCsy {
  display: table;
}

.header_rc-logo-search__1l9z3 {
  float: left;
  background-color: var(--blue);
}

.header_rc-logo-base__qgoba {
  display: table-cell;
  vertical-align: middle;
}

.header_rc-logo-base__qgoba img {
    position: relative;
    top: 2px;
    width: 100%;
  }

.header_rc-logo-mobile__3BqOQ {
  height: 52px;
  padding: 0 101px 0 15px;
}

.header_rc-logo-mobile__3BqOQ img {
    max-width: 180px;
  }

.header_rc-logo__3jyXT {
  height: 40px;
  padding: 0 20px;
  transition: width 1s;
}

/* Animations/Transitions */
.header_animate__30lun .header_rc-logo-wrap__2GCsy {
    height: 40px;
    overflow: hidden;
  }
.header_animate__30lun .header_rc-logo-wrap__2GCsy .header_rc-logo__3jyXT {
      position: relative;
      -webkit-animation: header_slidetop__1CNUw 0.25s ease-in-out;
              animation: header_slidetop__1CNUw 0.25s ease-in-out;
    }

.header_slidebottom__3Cvfw {
  position: relative;
  -webkit-animation: header_slidebottom__3Cvfw 0.25s ease-in-out, header_fade__2LeYs 0.25s ease-in;
          animation: header_slidebottom__3Cvfw 0.25s ease-in-out, header_fade__2LeYs 0.25s ease-in;
}

.header_active-nav__3z_8p {
  background-color: #017295;
}
/* Animation Definitions */
@-webkit-keyframes header_fade__2LeYs {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes header_fade__2LeYs {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes header_slidebottom__3Cvfw {
  0% {
    top: 100px;
  }
  100% {
    top: 0;
  }
}
@keyframes header_slidebottom__3Cvfw {
  0% {
    top: 100px;
  }
  100% {
    top: 0;
  }
}
@-webkit-keyframes header_slidetop__1CNUw {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
@keyframes header_slidetop__1CNUw {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
@-webkit-keyframes header_slidesearch__1fdgx {
  0% {
    top: -36px;
  }
  100% {
    top: 0;
  }
}
@keyframes header_slidesearch__1fdgx {
  0% {
    top: -36px;
  }
  100% {
    top: 0;
  }
}
@-webkit-keyframes header_slidesearch-small__V4AYF {
  0% {
    top: 36px;
  }
  100% {
    top: 0;
  }
}
@keyframes header_slidesearch-small__V4AYF {
  0% {
    top: 36px;
  }
  100% {
    top: 0;
  }
}

@media print {
  .header_component__1fkoT {
    display: none;
    margin: 0 !important;
  }
    .header_component__1fkoT .header_top__1XJtp {
      display: none !important;
    }
    .header_component__1fkoT nav {
      display: none !important;
    }
}

/* INSIGHT mobile header */

.navigation_overlayOn__1VLnu {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 25%;
  left: 0px;
  z-index: 2;
  display: block !important;
  background: var(--very-light-grey);
}

.navigation_overlay__2Dd9r {
  display: none;
}

.navigation_user-name__3g_ST {
  font-size: var(--font-size-h1);
  color: var(--black);
  letter-spacing: 1.2px;
}

.navigation_info-box__2L8pi {
  margin-left: 20px;
  margin-top: 40px;
}

.navigation_link-setting__wJwEP {
  display: block;
  letter-spacing: 1px;
  line-height: 26px;
  font: var(--font-family-simplon);
  font-size: var(--font-size-large);
}

.navigation_mobile-header-container__3R3vX {
  position: relative;
  background: var(--blue);
  height: 50px;
  text-align: center;
  padding: 0 50px;
}

.navigation_header-logo-container__2gRsl {
  padding: 14px 5px 10px 5px; /* Accommodate odd baseline of Simplon */
}

.navigation_header-logo__1gaZm {
  display: block;
  font-family: var(--font-family-simplon);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2.2px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--white) !important;
}

.navigation_header-logo__1gaZm sup {
    font-size: 10px;
    letter-spacing: 1.2px;
    line-height: 13px;
    margin-left: 3px;
    vertical-align: super;
    top: auto;
  }

.navigation_mobile-header-menu__z-7Jr {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  padding: 13px;
  background-color: transparent;
  border: none;
}

.navigation_header-container__37MRn {
  height: 40px;
  background-color: #007ea8;
  display: none;
}

@media (min-width: 600px) {
  .navigation_header-container__37MRn {
    display: block;
  }

  .navigation_mobile-header-container__3R3vX {
    display: none;
  }

  .navigation_learn-more__23s2Z {
    font-weight: bold;
    padding-top: 9px;
    display: inline-block;
  }

  .navigation_header-logo-container__2gRsl {
    /* width: 250px; */
    padding: 9px 20px 5px 20px; /* Accommodate odd baseline of Simplon */
  }

  .navigation_search-box-container__1pjkC {
    display: inline-block;
    width: calc(100% - 250px);
  }
}

:root {
  /* Fixed heights */
  --header-height: 76px;
  --footer-height: 128px;

  /* Colors
     *****************************************************************************
     Gray and brand colors for use across application
    */
  --roboto: Roboto-Regular;
  --charc: #0c2a31; /* "Dark Charc" */
  --charc-medium: #394859;
  --charc-transp: rgba(0, 0, 0, 0.15);
  --charc-transp-darker: rgba(0, 0, 0, 0.35);
  --grey-darkest: #26383d;
  --hover-grey: #dee1e4;
  --grey-darker: #4f5b66;
  --grey-dark: #8d9ca9;
  --grey: #a7adba;
  --grey-transp: rgba(57, 72, 89, 0.25);
  --grey-light: #dddddd;
  --grey-light-alt: #d4d3d3;
  --grey-lighter: #f4f4f4;
  --grey-lighter-alt: #eee;
  --grey-medium: #a3a3a3;
  --grey-medium-alt: #bbc0c4;
  --red: #ec5f67;
  --red-dark: #d6414a;
  --red-darker: #c53727;
  --red-darkest: #e2200a;
  --orange: #f99157;
  --yellow: #fac863;
  --green: #99c794;
  --blue-light: #5fb3b3;
  --light-shiny-blue: #00a4d6;
  --blue: #007ea8; /* "Berg Blue" */
  --blue-dark: #0f6c8d; /* "Berg Tint 2" */
  --blue-darker: #017295; /* "Berg Tint 3" */
  --blue-darkest: #014155; /* "Berg Tint 4" */
  --blue-link: #3d6aa6; /* Default link color */
  --blue-lighter: #f5f9fc; /* "Berg Tint 1" */
  --blue-lighter-alt: #00c3ff;
  --aqua-blue: #26b6df;
  --sky-blue: #c9f0fb;
  --light-grey: #c8c8c8;
  --very-light-grey: #f4f4f4;
  --grey-border: #dee1e0;
  --dark-grey: #143138;
  --purple: #c594c5;
  --brown: #ab7967;
  --black: #000000;
  --white: #ffffff;
  --white-transp: rgba(255, 255, 255, 0.35);
  --white-transp-darker: rgba(255, 255, 255, 0.15);
  --highlightColor: hwb(190, 35%, 20%);

  --successColor: #3c763d;
  --warningColor: #8a6d3b; /* #EFBF4F;*/
  --errorColor: #e34c53;
  --warningIcon: #f0c043;
  /* End Colors */

  /* Typography
     *****************************************************************************
     Font, line-height, and color for body text
    */

  --font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-serif: Georgia, "Times New Roman", Times, serif;
  --font-family-roboto: "Roboto";
  --font-family-simplon: "Simplon Norm";
  --font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
  --font-family-zawgyi-one: "Zawgyi-One";
  --font-family-base: var(--font-family-sans-serif);
  --font-family-simplon-bold: "SimplonNorm-Bold";
  --font-family-simplon-light: "SimplonNorm-Light";
  --font-family-simplon-medium: "SimplonNorm-Medium";

  --font-size-base: 15px;
  --font-size-h1: calc(var(--font-size-base) * 2.6);
  --font-size-h2: calc(var(--font-size-base) * 2.1);
  --font-size-h3: calc(var(--font-size-base) * 1.7);
  --font-size-h4: calc(var(--font-size-base) * 1.25);
  --font-size-h5: var(--font-size-base);
  --font-size-h6: calc(var(--font-size-base) * 0.85);
  --font-size-large: calc(var(--font-size-base) * 1.25);
  --font-size-small: calc(var(--font-size-base) * 0.8);
  --font-size-very-small: calc(var(--font-size-base) * 0.73);
  --font-size-normal: var(--font-size-base);
  --font-size-medium: calc(var(--font-size-base) * 0.9333333);
  --font-size-extra-large: calc(var(--font-size-base) * 1.4666666);

  --font-weight-normal: normal;
  --font-weight-light: light;
  --font-weight-bold: bold;

  --headings-font-family: inherit;
  --headings-font-weight: 500;
  --headings-line-height: 1.1;
  --headings-color: inherit;
  --headings-small-color: var(--grey-light);

  --line-height-base: 1.4;
  --line-height-computed: calc(var(--font-size-base) * var(--line-height-base));
  /* End Typography */

  /* Transitions */
  --default-transition-duration: 0.25s;
  --default-transition-easing: ease-in-out;
  --transition-all: all var(--default-transition-duration)
    var(--default-transition-easing);

  --max-site-width: 1280px;
}

/* Roboto */

/* Material Icons */

/* Simplon Regular */
@font-face {
  font-family: "Simplon Norm";
  src: url(/static/media/simplonnorm-regular.817b4e60.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Simplon Regular Italic */
@font-face {
  font-family: "Simplon Norm";
  src: url(/static/media/simplonnorm-regularitalic.a9b10d3f.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

/* Simplon Bold */
@font-face {
  font-family: "Simplon Norm";
  src: url(/static/media/simplonnorm-bold.3fd735b2.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

/* Simplon Bold Italic */
@font-face {
  font-family: "Simplon Norm";
  src: url(/static/media/simplonnorm-bolditalic.d1d18d6b.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}

/* Simplon Light */
@font-face {
  font-family: "Simplon Norm";
  src: url(/static/media/simplonnorm-light.fa42eebe.woff) format("woff");
  font-weight: 100;
  font-style: normal;
}

/* Simplon Light Italic */
@font-face {
  font-family: "Simplon Norm";
  src: url(/static/media/simplonnorm-lightitalic.6012574d.woff) format("woff");
  font-weight: 100;
  font-style: italic;
}

/* Simplon Medium */
@font-face {
  font-family: "Simplon Norm";
  src: url(/static/media/simplonnorm-medium.5fcdb4d5.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

/* Simplon Medium Italic */
@font-face {
  font-family: "Simplon Norm";
  src: url(/static/media/simplonnorm-mediumitalic.9b7578a1.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

